
import React from 'react';
import {useState, useEffect} from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    SimpleFormIterator,
    DateTimeInput,
    DateInput,
    ArrayInput,
    ArrayField,
    EmailField,
    useRecordContext,
    BooleanInput,
    BooleanField,
} from 'react-admin';

import {
    StandardPagination
} from '../common.js'

const alarmFeedbackUsefulness = [
    { id: 0, name: "Strongly disagree" },
    { id: 1, name: "Disagree" },
    { id: 2, name: "Neither agree or disagree" },
    { id: 3, name: "Agree" },
    { id: 4, name: "Strongly agree" }
];

function renderAlarmFeedbackRating(record) {

    const rating = record.rating;
    const m = alarmFeedbackUsefulness[rating];
    if (m) {
        return m.name
    } else {
        return `Unknown rating: '${rating}'`
    }
}

const listFilters = [

    <ReferenceArrayInput source="creator_id" reference="users" label="Creator" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,

    <SearchInput source="q" alwaysOn/>,
]

export const AlarmFeedbackList = props => (
    <List pagination={<StandardPagination />} filters={listFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <DateField source="created_at" showTime/>
            <ReferenceField source="alarm_id" reference="alarm"><TextField source="id"/></ReferenceField>
            <ReferenceField label="Creator" source="creator_id" reference="users"><TextField source="name"/></ReferenceField>
            <TextField source="comment"/>
            <FunctionField source="rating" render={renderAlarmFeedbackRating}/>
        </Datagrid>
    </List>
);
// create feedback for alarm
export const AlarmFeedbackCreate = props => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ReferenceInput label="Alarm" reference="alarm" source="alarm_id">
                    <SelectInput optionText="id" optionValue="id" />
                </ReferenceInput>
                <TextInput source="comment" />
                <SelectInput
                    source="rating"
                    choices={alarmFeedbackUsefulness}
                    label="This alarm was useful"
                />
            </SimpleForm>
        </Create>
    );
};

// edit feedback for alarm
export const AlarmFeedbackEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Feedback id" source="id"/>
            <DateInput disabled source="created_at" label='Created'/>
            <ReferenceField source="alarm_id" reference="alarm"><TextField source="id"/></ReferenceField>
            <ReferenceField label="Creator" source="creator_id" reference="users"><TextField source="name"/></ReferenceField>
            <TextInput source="comment"/>
            <SelectInput
                    source="rating"
                    choices={alarmFeedbackUsefulness}
                    label="This alarm was useful"
                />
        </SimpleForm>
    </Edit>
);
