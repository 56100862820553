import {getListDefault} from './dataProvider.js';


export const getAssetParent = async (params) => {
    let unlim_params = {pagination: {perPage: 100000, page: 1}, sort: {field: "id", order: "DESC"}, filter: {}}
    let assets = (await getListDefault("assets", params))
    let rooms = (await getListDefault("rooms", unlim_params)).data
    let buildings = (await getListDefault("buildings", unlim_params)).data

    assets.data.forEach( asset => {
        let room = rooms.find(room => room.id === asset.room_id)
        let building = buildings.find(building => building.id === room.building_id)
        asset.room = room
        asset.building = building
      }
    )

    return Promise.resolve(assets)
  }


export const getRoomWithBuilding = async (params) => {
    let unlim_params = {pagination: {perPage: 100000, page: 1}, sort: {field: "id", order: "DESC"}, filter: {}}
    let rooms = (await getListDefault("rooms", params))
    let buildings = (await getListDefault("buildings", unlim_params)).data

    rooms.data.forEach( room => {
        let building = buildings.find(building => building.id === room.building_id)
        room.building = building
      }
    )

    return Promise.resolve(rooms)
  }