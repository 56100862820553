import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
} from 'react-admin';

import {JsonField, JsonInput} from "react-admin-json-view";

import {
    Edit,
    SimpleForm,
    SearchInput,
} from 'react-admin';


const preferenceSearch = [
    <SearchInput source="category" alwaysOn/>,
    <SearchInput source="user_id" alwaysOn/>,
]

export const PreferencesList = props => (
    <List filters={preferenceSearch} {...props}>
        <Datagrid rowClick="edit">
            <ReferenceField source="user_id" reference="users"><TextField source="name"/></ReferenceField>
            <TextField source="category"/>
            <TextField source="preferences"/>
        </Datagrid>
    </List>
);

export const PreferencesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceField source="user_id" reference="users"><TextField source="name"/></ReferenceField>
            <TextField source="category"/>
            <JsonInput source="preferences"/>
        </SimpleForm>
    </Edit>
);