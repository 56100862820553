import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField, DateInput, DateTimeInput, BooleanInput, FormDataConsumer,
} from 'react-admin';

import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
} from 'react-admin';

import {
    StandardPagination
} from '../common.js'
import {useForm} from "react-final-form";

const deviceListFilters = [
    <ReferenceArrayInput source="organization_id" reference="organizations" label="Organizations" perPage={10000}
                         alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="type_id" reference="devicetypes" label="Types" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="model"/>
    </ReferenceArrayInput>,
    <SearchInput source="q" alwaysOn/>,
]

const deviceSearch = (record) => {
    if (record === null)
        return null

    return `${record.serial} - ${record.id}`
}

export const DeviceList = props => (
    <List pagination={<StandardPagination/>} filters={deviceListFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Id" source="id"/>
            <TextField source="serial"/>
            <TextField label="Hardware ID" source="device_id"/>
            <ReferenceField label="Type" source="type_id" reference="devicetypes">
                <FunctionField render={record => `${record.brand} ${record.model}`}/>
            </ReferenceField>
            <ReferenceField source="organization_id" reference="organizations"><TextField
                source="name"/></ReferenceField>
            <DateField source="registered_at" label='Registered' showTime/>
            <TextField source="nickname"/>
            <TextField source="description"/>
        </Datagrid>
    </List>
);

//Wont work, edit device uses patch method, not put..
export const DeviceEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField label="Id" source="id"/>
            <TextField source="serial"/>
            <TextField label="Hardware ID" source="device_id"/>
            <ReferenceField label="Type" source="type_id" reference="devicetypes">
                <FunctionField render={record => `${record.brand} ${record.model}`}/>
            </ReferenceField>
            <ReferenceField label="Organization" source="organization_id" reference="organizations"><TextField
                source="name"/></ReferenceField>
            <ReferenceField label="Creator" source="registered_by" reference="users"><TextField
                source="name"/></ReferenceField>
            <DateField source="registered_at" label='Registered' showTime/>
            <TextInput source="nickname"/>
            <TextInput source="description"/>
        </SimpleForm>
    </Edit>
);

export const DeviceCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label='Device type' reference='devicetypes' source="type">
                <SelectInput optionText="model"/>
            </ReferenceInput>
            <TextInput source="serial"/>
            <TextInput source="revision"/>
            <TextInput source="api_key"/>
        </SimpleForm>
    </Create>
);


export const DeviceEventTypeList = props => (
    <List pagination={<StandardPagination/>} filters={deviceListFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Id" source="id"/>
            <TextField source="name"/>
            <TextField source="description"/>
            <ReferenceField source="created_by" reference="users"><TextField source="name"/></ReferenceField>
            <DateField source="created_at" label='Created At' showTime/>
        </Datagrid>
    </List>
);


export const DeviceEventTypeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <TextInput source="description"/>
        </SimpleForm>
    </Create>
);


export const DeviceEventList = props => (
    <List pagination={<StandardPagination/>} filters={deviceListFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Id" source="id"/>
            <ReferenceField source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
            <TextField source="start_time" label='Start Time' showTime/>
            <TextField source="end_time" label='End Time' showTime/>
            <ReferenceField source="created_by" reference="users"><TextField source="name"/></ReferenceField>
            <ReferenceField source="type_id" reference="deviceeventtypes"><TextField source="name"/></ReferenceField>
            <DateField source="created_at" label='Created At' showTime/>
        </Datagrid>
    </List>
);

const transform = data => {
    delete data.device
    delete data.type
    delete data.created_by
    delete data.created_at
    delete data.id

    return data
}
export const DeviceEventEdit = (props) => (
    <Edit {...props} transform={transform}>
        <SimpleForm>
            <TextField label="Id" source="id"/>
            <DateTimeInput source="start_time" label='Start Time'/>
            <DateTimeInput source="end_time" label='End Time'/>
            <ReferenceField source="created_by" reference="users"><TextField source="name"/></ReferenceField>
            <ReferenceInput label="Device" reference="devices" source="device_id" perPage={10000}>
                <AutocompleteInput optionText={deviceSearch}/>
            </ReferenceInput>
            <ReferenceInput label="Device Event Type" reference="deviceeventtypes" source="type_id" perPage={10000}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <DateField source="created_at" label='Created At' showTime/>
        </SimpleForm>
    </Edit>
);


const transformCreate = data => {

    let auto_end_time = data.auto_end_time
    delete data.auto_end_time
    if (auto_end_time) {
        data.end_time = data.start_time
    }

    return data
}
export const DeviceEventCreate = (props) => {
    return (
        <Create {...props} transform={transformCreate}>
            <SimpleForm>
                <DateTimeInput source="start_time" label='Start Time'/>
                <BooleanInput source="auto_end_time" label="End time same as start Time"/>
                <FormDataConsumer>
                    {({formData, ...rest}) => !formData.auto_end_time && (
                        <DateTimeInput source="end_time" label='End Time'/>)}
                </FormDataConsumer>
                <ReferenceInput label="Device Event Type" reference="deviceeventtypes" source="type_id" perPage={10000}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <ReferenceInput label="Device" reference="devices" source="device_id" perPage={10000}>
                    <AutocompleteInput optionText={deviceSearch}/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
}