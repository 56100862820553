import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    Datagrid, Edit,
    List, ReferenceArrayInput,
    ReferenceField,
    ReferenceInput, SearchInput,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import {StandardPagination} from "../common";
import React from "react";
import {JsonField, JsonInput} from "react-admin-json-view";

export const GatewayConfigCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="device_id" reference="devices" perPage={10000}>
              <AutocompleteInput optionText="serial"/>
            </ReferenceInput>
            <ReferenceInput source="vpn_server_id" reference="vpnservers" perPage={10000}>
              <AutocompleteInput optionText="host"/>
            </ReferenceInput>
            <TextInput source="gateway_user"/>
            <TextInput source="gateway_static_ip"/>
            <TextInput source="subnet"/>
            <TextInput source="vpn_user"/>
            <TextInput source="gateway_secrets.vpn_password" label="Vpn Password"/>
            <TextInput source="gateway_secrets.gateway_password" label="Gateway Password"/>
            <JsonInput source="meta" reactJsonOptions={{name: null}}/>

        </SimpleForm>
    </Create>
);

export const GatewayConfigEdit = props => {

    const transform = data => {
        delete data["device_api_key"]
        delete data["id"]
        delete data["gateway_secrets"]["id"]
        delete data["vpn_server"]
        return data
    }

    return <Edit {...props} transform={transform}>
        <SimpleForm>
            <ReferenceInput source="device_id" reference="devices" perPage={10000}>
                <AutocompleteInput optionText="serial"/>
            </ReferenceInput>
            <ReferenceInput source="vpn_server_id" reference="vpnservers" perPage={10000}>
                <AutocompleteInput optionText="host"/>
            </ReferenceInput>
            <TextInput source="gateway_user"/>
            <TextInput source="gateway_static_ip"/>
            <TextInput source="subnet"/>
            <TextInput source="vpn_user"/>
            <TextInput source="gateway_secrets.vpn_password" label="Vpn Password"/>
            <TextInput source="gateway_secrets.gateway_password" label="Gateway Password"/>
            <JsonInput source="meta" reactJsonOptions={{name: null}}/>

        </SimpleForm>
    </Edit>
};

const deviceListFilters = [
    <ReferenceArrayInput source="device_id" reference="devices" label="Device" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="serial"/>
    </ReferenceArrayInput>,
    <ReferenceArrayInput source="vpn_server_id" reference="vpnservers" label="Vpn Server" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="host"/>
    </ReferenceArrayInput>,
    <SearchInput source="q" alwaysOn/>,
]

export const GatewayConfigList = props => (
    <List pagination={<StandardPagination />} perPage={25} filters={deviceListFilters} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
            <TextField source="gateway_static_ip"/>
            <TextField source="subnet"/>
            <TextField source="vpn_user"/>
            <TextField source="gateway_user"/>
            <JsonField source="meta"/>
            <ReferenceField source="created_by" reference="users" perPage={10000}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="vpn_server_id" reference="vpnservers" perPage={10000}>
                <TextField source="host"/>
            </ReferenceField>
        </Datagrid>
    </List>
);