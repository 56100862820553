import {
    AutocompleteInput, ChipField,
    Create,
    Datagrid, DateField, Edit,
    List, NumberField,
    NumberInput, ReferenceField,
    ReferenceInput, ReferenceManyField,
    SimpleForm, SingleFieldList, TextField,
    TextInput
} from "react-admin";
import React from "react";
import {StandardPagination} from "../common";

export const AssetMetricsTagCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="asset_id" reference="assets" perPage={1000}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="metric"/>
            <TextInput source="description"/>
        </SimpleForm>
    </Create>
);

export const AssetMetricsTagEdit = props => {

    const transformEdit = data => {
        delete data.registered_by
        delete data.created_at
        delete data.id
        return data
    }

    return (
        <Edit transform={transformEdit} {...props}>
            <SimpleForm>
                <ReferenceInput source="asset_id" reference="assets" perPage={1000}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <TextInput source="metric"/>
                <TextInput source="description"/>
            </SimpleForm>
        </Edit>
    )
}

export const AssetMetricsTagList = props => (
    <List pagination={<StandardPagination/>} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField source="asset_id" reference="assets" perPage={10000}>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="asset_id"/>
            <TextField source="metric"/>
            <TextField source="description"/>
            <ReferenceField source="registered_by" reference="users" perPage={10000}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="created_at" showTime/>
        </Datagrid>
    </List>
);