
import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
    SimpleFormIterator,
    DateTimeInput,
    DateInput,
    ArrayInput,
    ArrayField,
    useRecordContext,
} from 'react-admin';

import {JsonField, JsonInput} from "react-admin-json-view";

//import { styled } from '@mui/system';
import Chip from '@material-ui/core/Card';

import {
    StandardPagination
} from '../common.js'

const anomalyChoices = [
    { id: "NO", name: "No" },
    { id: "MAYBE", name: "Maybe" },
    { id: "YES", name: "Yes" },
]

const deviceSearch = (record) => {
    if (record === null)
        return null

    return `${record.serial} - ${record.id}`
}

const listFilters = [

    <ReferenceArrayInput source="device_id" reference="devices" label="Devices" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="serial"/>
    </ReferenceArrayInput>,

/*
    <ReferenceArrayInput source="type_id" reference="devicetypes" label="Types" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="model"/>
    </ReferenceArrayInput>,
*/
    <SearchInput source="q" alwaysOn/>,
]

// Display inline array item (not list of objects)
const ChipItem = () => {
    const record = useRecordContext();
    return <Chip sx={{ margin: 4 }} key={record}>{record}</Chip>
}

export const AnnotationList = props => (
    <List pagination={<StandardPagination />} filters={listFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField label="Device" source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
            <DateField source="start_time" showTime/>
            <DateField source="end_time" showTime/>

            <DateField source="created_at" showTime/>
            <ReferenceField label="Creator" source="creator_id" reference="users"><TextField source="name"/></ReferenceField>
            <TextField source="anomaly"/>
            <TextField source="description"/>
            <TextField source="tags"/>

        </Datagrid>
    </List>
);


export const AnnotationCreate = props => {
    return (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput label='Model Template' reference='ml/templates' source="model_template_id" perPage={1000}>
                <AutocompleteInput optionText="name" optionValue="id" />
            </ReferenceInput>


            <ReferenceInput label="Device" reference="devices" source="device_id" perPage={10000} >
                <AutocompleteInput optionText={deviceSearch}/>
            </ReferenceInput>

            <SelectInput source="anomaly" choices={anomalyChoices} label="Is this an anomaly?" />
            <TextInput source="description" />
            <ArrayInput source="tags" label="Tags">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>
            <DateTimeInput source="start_time" />
            <DateTimeInput source="end_time" />
            <JsonInput source="meta" reactJsonOptions={{ name: null }} />
        </SimpleForm>
    </Create>
)};

export const AnnotationEdit = (props) => {
    const transform = data => {
        delete data['model_template_id']
        delete data['device_id']
        delete data['created_at']
        delete data['creator_id']
        delete data['id']
        return data;
    };
    return (
    <Edit {...props} transform={transform}>
        <SimpleForm>
            <TextField label="Id" source="id"/>
            <DateField showTime source="created_at" label='Created'/>
            <ReferenceField label="Creator" source="creator_id" reference="users"><TextField source="name"/></ReferenceField>
            <ReferenceField label="Model Template" source="model_template_id" reference="ml/templates"><TextField source="id"/></ReferenceField>
            <ReferenceField label="Device" source="device_id" reference="devices"><TextField source="serial"/></ReferenceField>
            <SelectInput source="anomaly" choices={anomalyChoices} label="Is this an anomaly?" />
            <TextInput source="description"/>
            <ArrayInput source="tags" label="Tags">
                <SimpleFormIterator>
                    <TextInput />
                </SimpleFormIterator>
            </ArrayInput>
            <DateTimeInput source="start_time" />
            <DateTimeInput source="end_time" />
            <JsonInput source="meta" reactJsonOptions={{ name: null }} />
        </SimpleForm>
    </Edit>
)};


