import {
    AutocompleteInput, ChipField,
    Create,
    Datagrid,
    List, NumberField,
    NumberInput, ReferenceField,
    ReferenceInput, ReferenceManyField,
    SimpleForm, SingleFieldList, TextField,
    TextInput
} from "react-admin";
import React from "react";
import {StandardPagination} from "../common";

export const VpnServerCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="host"/>
            <TextInput source="type"/>
            <TextInput source="public_key"/>
        </SimpleForm>
    </Create>
);

export const VpnServerList = props => (
    <List pagination={<StandardPagination />} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="host"/>
            <TextField source="type"/>
            <TextField source="public_key" label={"Public Key"}/>
            <ReferenceField source="created_by" reference="users" perPage={10000}>
                <TextField source="name"/>
            </ReferenceField>
        </Datagrid>
    </List>
);