import React from 'react';
import {useState, useEffect} from 'react';

import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  AutocompleteInput,
  FunctionField,
  DateField,
  Edit,
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  SearchInput,
  NumberInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  SimpleFormIterator,
  DateTimeInput,
  DateInput,
  ArrayInput,
  ArrayField,
  EmailField,
  useRecordContext,
  BooleanInput,
  BooleanField,
} from 'react-admin';

import {
  StandardPagination
} from '../common.js'
import {JsonField, JsonInput} from "react-admin-json-view";


const listFilters = [
  <ReferenceArrayInput source="device_id" reference="devices" label="Devices" perPage={10000} alwaysOn>
    <AutocompleteArrayInput optionText="serial"/>
  </ReferenceArrayInput>,

  <ReferenceArrayInput source="creator_id" reference="users" label="Creator" perPage={10000} alwaysOn>
    <AutocompleteArrayInput optionText="name"/>
  </ReferenceArrayInput>,


  <SearchInput source="q" alwaysOn/>,
]

export const IntegrationServiceList = props => (
  <List pagination={<StandardPagination/>} filters={listFilters} perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id"/>
      <TextField source="name"/>
      <TextField source="api_url"/>
      <TextField source="description"/>
    </Datagrid>
  </List>
);

export const IntegrationServiceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name"/>
      <TextInput source="api_url"/>
      <TextInput source="description"/>
    </SimpleForm>
  </Edit>
);
export const IntegrationServiceCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name"/>
        <TextInput source="api_url"/>
        <TextInput source="description"/>
      </SimpleForm>
    </Create>
  );
};

export const IntegrationList = props => (
  <List pagination={<StandardPagination/>} filters={listFilters} perPage={25} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id"/>
      <TextField source="api_url"/>
      <ReferenceField source="organization_id" reference="organizations"><TextField source="name"/></ReferenceField>
      <ReferenceField source="integration_service_id" reference="integrationservice"><TextField
        source="name"/></ReferenceField>
      <JsonField source="configuration" reactJsonOptions={{ name: null }}/>
    </Datagrid>
  </List>
);

export const IntegrationEdit = (props) => {

    const transform = data => {
      delete data["integration_service"]
      delete data["id"]
      return data
    }

    return <Edit {...props} transform={transform}>
      <SimpleForm>
        <TextInput source="api_url"/>
        <ReferenceInput
          label="Organization"
          reference="organizations"
          source="organization_id"
          perPage={1000}>
          <AutocompleteInput optionText="name" optionValue="id"/>
        </ReferenceInput>
        <ReferenceInput
          label="Integration Service"
          reference="integrationservice"
          source="integration_service_id"
          perPage={1000}>
          <AutocompleteInput optionText="name" optionValue="id"/>
        </ReferenceInput>
        <TextInput source="user_name"/>
        <TextInput source="password"/>
        <TextInput source="api_key"/>
        <JsonInput source="configuration" reactJsonOptions={{ name: null }}/>
      </SimpleForm>
    </Edit>
  }
;

export const IntegrationCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="api_url"/>
        <ReferenceInput
          label="Organization"
          reference="organizations"
          source="organization_id"
          perPage={1000}>
          <AutocompleteInput optionText="name" optionValue="id"/>
        </ReferenceInput>
        <ReferenceInput
          label="Integration Service"
          reference="integrationservice"
          source="integration_service_id"
          perPage={1000}>
          <AutocompleteInput optionText="name" optionValue="id"/>
        </ReferenceInput>
        <TextInput source="user_name"/>
        <TextInput source="password"/>
        <TextInput source="api_key"/>
        <JsonInput source="configuration" reactJsonOptions={{ name: null }}/>
      </SimpleForm>
    </Create>
  );
};
