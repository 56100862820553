
import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
} from 'react-admin';

import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SearchInput,
    SelectInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
} from 'react-admin';

import {
    StandardPagination
} from '../common.js'

const roomListFilters = [
    <ReferenceArrayInput source="building_id" reference="buildings" label="Buildings" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,
    <SearchInput source="q" alwaysOn/>,
]

export const RoomCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="building_id" reference="buildings" perPage={10000}>
              <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="name"/>
            <TextInput source="nice_name"/>
            <TextInput source="floor"/>
            <NumberInput source="latitude"/>
            <NumberInput source="longitude"/>
        </SimpleForm>
    </Create>
);

export const RoomList = props => (
    <List pagination={<StandardPagination />} filters={roomListFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField source="building_id" reference="buildings" perPage={10000}>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="name"/>
            <TextField source="nice_name"/>
            <TextField source="floor"/>
            <NumberField source="latitude"/>
            <NumberField source="longitude"/>
            <ReferenceManyField label="Assets" reference="assets" target="room_id" perPage={10000}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
        </Datagrid>
    </List>
);

export const RoomEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <ReferenceInput source="building_id" reference="buildings" perPage={10000}>
                <AutocompleteInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="name"/>
            <TextInput source="nice_name"/>
            <TextInput source="floor"/>
            <NumberInput source="latitude"/>
            <NumberInput source="longitude"/>
        </SimpleForm>
    </Edit>
);
