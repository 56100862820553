
import React from 'react';

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
} from 'react-admin';

import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ChipField,
} from 'react-admin';

import {
    StandardPagination
} from '../common.js'


const buildingListFilters = [
    <ReferenceArrayInput source="organization_id" reference="organizations" label="Organizations" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,
    <SearchInput source="q" alwaysOn/>,
]

export const BuildingCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <ReferenceInput source="organization_id" reference="organizations" perPage={1000}>
                <AutocompleteInput optionText="name"/></ReferenceInput>
            <TextInput source="name"/>
            <TextInput source="site"/>
            <TextInput source="address"/>
            <NumberInput source="latitude"/>
            <NumberInput source="longitude"/>
        </SimpleForm>
    </Create>
);

export const BuildingEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceInput source="organization_id" reference="organizations" perPage={1000}><AutocompleteInput
                optionText="name"/></ReferenceInput>
            <TextInput disabled source="id"/>
            <TextInput source="name"/>
            <TextInput source="site"/>
            <TextInput source="address"/>
            <NumberInput source="latitude"/>
            <NumberInput source="longitude"/>
            <ReferenceManyField label="Rooms" reference="rooms" target="building_id" perPage={10000}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
        </SimpleForm>
    </Edit>
);

export const BuildingList = props => (
    <List pagination={<StandardPagination />} filters={buildingListFilters} perPage={25} {...props} >
        <Datagrid rowClick="edit">
            <ReferenceField source="organization_id" reference="organizations"><TextField source="name"/></ReferenceField>
            <TextField source="id"/>
            <TextField source="name"/>
            <TextField source="site"/>
            <TextField source="address"/>
            <NumberField source="longitude"/>
            <NumberField source="latitude"/>
            <ReferenceManyField label="Rooms" reference="rooms" target="building_id" perPage={10000}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>
        </Datagrid>
    </List>
);

