import React, {useState} from 'react';

import {
    List,
    Datagrid,
    TextField,
    SingleFieldList,
    AutocompleteInput,
    SimpleFormIterator,
    ReferenceManyField,
    ReferenceArrayField,
    ChipField, FormDataConsumer
} from 'react-admin';

import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    ArrayInput,
    SearchInput,
} from 'react-admin';

import {
    UserIdentifier,
    DeviceIdentifier,
    StandardPagination
} from '../common.js'

const LengthField = (props) => {
    // Used within an ArrayField, to display the length of the array instead
    // of displaying all items.
    return props.ids.length
};

const organizationListFilters = [
    <SearchInput source="q" alwaysOn/>,
]

export const OrganizationList = props => (
    <List pagination={<StandardPagination/>} filters={organizationListFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Id" source="id"/>
            <TextField source="name"/>

            <ReferenceManyField label="Buildings" reference="buildings" target="organization_id">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceManyField>

            <ReferenceArrayField label="Users" reference="users" source="user_ids">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>

            <ReferenceArrayField label="Devices" reference="devices" source="device_ids" perPage={10}>
                <LengthField/>
            </ReferenceArrayField>

        </Datagrid>
    </List>
);

export const OrganizationEdit = (props) => {

    console.log("OrganizationEdit", props);

    return <Edit {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id"/>
            <TextInput source="name"/>

            <ReferenceManyField label="Buildings" reference="buildings" target="organization_id">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceManyField>

            <ArrayInput source="users">
                <SimpleFormIterator>
                    <ReferenceInput label="User" source="id" reference="users" perPage={1000}>
                        <AutocompleteInput optionText={UserIdentifier}/>
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>

            <ArrayInput source="devices">
                <SimpleFormIterator disableReordering>
                    <FormDataConsumer>
                        {({getSource, scopedFormData}) => {
                            if (scopedFormData && (scopedFormData.serial !== undefined)) {
                                    console.log(scopedFormData)
                                    console.log(getSource("serial"))
                                return (
                                    <TextField
                                        source={"serial"}
                                        record={scopedFormData}
                                    />
                                );
                            } else {
                                return (
                                    <ReferenceInput record={scopedFormData} label="Device" source={getSource("id")} reference="devices" perPage={5000}>
                                        <AutocompleteInput optionText={DeviceIdentifier}/>
                                    </ReferenceInput>
                                )
                            }
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>

        </SimpleForm>
    </Edit>
};

export const OrganizationCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput disabled label="Id" source="id"/>
            <TextInput source="name"/>
        </SimpleForm>
    </Create>
);
