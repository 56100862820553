import {
    ArrayField,
    ArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DateTimeInput,
    Edit, FormDataConsumer,
    List,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
    useRefresh
} from "react-admin";
import {DeviceIdentifier, StandardPagination} from "../common";
import {JsonInput} from "react-admin-json-view";
import React, {useState} from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import {useForm} from "react-final-form";
import dataProvider, {apiUrl} from "../dataProvider";
import CardHeader from "@material-ui/core/CardHeader";

const modelTemplateListFilters = [
    <ReferenceArrayInput source="model_type_id" reference="modeltype" label="Type" perPage={1000} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,
    <SearchInput source="q" alwaysOn/>,
]




const AddDevicesFromOrg = props => {

    const [org, setOrg] = useState()
    const [deviceType, setDeviceType] = useState()

    const form = useForm();
    const previousDevices = form.getState().values.devices

    const onClick = () => {
        const response = dataProvider.getList("devices", {
            pagination: {page: 1, perPage: 10000},
            sort: {field: "registered_at", order: "asc"},
            filter: {}
        }).then((data, total) => {
            const allDevices = data.data
            const orgDevices = allDevices.filter(d => d.organization_id === org)
            const orgDevicesOfType = orgDevices.filter(d => d.type_id === deviceType)

            let newDevices = previousDevices
            orgDevicesOfType.forEach(device => {
                if (previousDevices.findIndex(prevDevice => prevDevice.id === device.id) === -1) {
                    newDevices.push(device)
                    // Need to change the form for each added device.
                    // If one adds the complete array at the end instead,
                    // the device reference list will have invisible items.
                    form.change("devices", newDevices)
                    form.reset()
                }
            });
        })
    }

    return (
        <Card variant="outlined">
            <CardHeader title="Add all devices from an organization of device type"/>
            <ReferenceInput label="Organization" source="unused" reference="organizations" perPage={1000}>
                <AutocompleteInput validate={[]} onInputValueChange={(value) => {
                    setOrg(value)
                }}/>
            </ReferenceInput>
            <ReferenceInput label='Device type' reference='devicetypes' source="type">
                <AutocompleteInput optionText="model" onInputValueChange={(value) => {
                    setDeviceType(value)
                }}/>
            </ReferenceInput>
            <Button color="primary" onClick={() => onClick()}>Add</Button>
            <Button>Nr of devices: {form.getState().values.devices.length}</Button>
        </Card>
    )
}


const AddOrRemoveOne = props => {

    const refresh = useRefresh()

    const [device, setDevice] = useState()


    const form = useForm();
    const previousDevices = form.getState().values.devices

    const putModelTemplateDevices = async (device_id, method) => {
        let url = `${apiUrl}/v1/ml/templates/${props.record.id}/devices`
        let options  = {
            'credentials': 'omit',
        }
        let headers = new Headers({
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + process.env.REACT_APP_API_KEY,

        })
        options['headers'] = headers
        let body = {}
        if (method === 'ADD'){
            body = {'add': [device_id]}
        }
        if (method === 'REMOVE') {
            body = {'remove': [device_id]}
        }

        body = JSON.stringify(body)

        return await fetch(url, {...options, method: "PUT", body: body})
    }

    const onClickAdd = async () => {
        const response = dataProvider.getOne("devices", {
            id: device
        }).then((data, total) => {
            let apiResponse = putModelTemplateDevices(data.data.id, "ADD")
            apiResponse.then( r => {
                refresh()
            })
        })
    }

    const onClickRemove = async () => {
        let apiResponse = putModelTemplateDevices(device, "REMOVE")
            apiResponse.then( r => {
                refresh()
            })
    }

    return (
        <Card variant="outlined">
            <CardHeader title="Add or remove one device"/>
            <ReferenceInput label="Device" source="id" reference="devices" perPage={10000}>
                <AutocompleteInput validate={[]} onInputValueChange={(value) => {
                    setDevice(value)
                }} optionText={DeviceIdentifier}/>
            </ReferenceInput>
            <Button color="primary" onClick={() => onClickAdd()}>Add</Button>
            <Button color="primary" onClick={() => onClickRemove()}>Remove</Button>
            <Button>Nr of devices: {form.getState().values.devices.length}</Button>
        </Card>
    )
}

const LengthField = (props) => {
    // Used within an ArrayField, to display the length of the array instead
    // of displaying all items.
    return props.ids.length
};


export const ModelTemplateList = props => (
    <List pagination={<StandardPagination />} filters={modelTemplateListFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">

            <TextField source="nice_name"/>
            <TextField source="name"/>
            <NumberField source="version"/>
            <DateField source="start_time" showTime/>
            <DateField source="end_time" showTime/>
            <DateField source="created_at"/>
            <BooleanField source="enabled"/>
            <ReferenceField source="model_type_id" reference="modeltype" label='Type'>
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="target"/>
            <ArrayField source="devices">
                <LengthField/>
            </ArrayField>

            <TextField source="description"/>
        </Datagrid>
    </List>
);

export const ModelTemplateCreate = props => {

    const choices = [
        {id: 'device', name: 'Device'},
        {id: 'server', name: 'Server'},
        {id: 'external', name: 'External'},
    ];

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput source="name"/>
                <NumberInput source="version"/>
                <TextInput source="nice_name"/>
                <TextInput source="description"/>
                <TextInput source="model_type"/>
                <ReferenceInput source="model_type_id" reference="modeltype" label='Type' perPage={1000}>
                    <SelectInput optionText="name" optionValue="id"/>
                </ReferenceInput>
                <SelectInput source="target" choices={choices}/>
                <ArrayInput source="devices">
                    <SimpleFormIterator>
                        <ReferenceInput label="Device" reference="devices" perPage={10000}>
                            <AutocompleteInput optionText={DeviceIdentifier}/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
                <JsonInput source="training_parameters" reactJsonOptions={{name: null}}/>
                <JsonInput source="meta" reactJsonOptions={{name: null}}/>
                <DateTimeInput source="start_time"/>
                <DateTimeInput source="end_time"/>
                <BooleanInput source="enabled"/>
            </SimpleForm>
        </Create>
    )
}


export const ModelTemplateEdit = props => {

    const [show, setShow] = useState(false)

    const choices = [
        {id: 'device', name: 'Device'},
        {id: 'server', name: 'Server'},
        {id: 'external', name: 'External'},
    ];
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput source="name"/>
                <NumberInput source="version"/>
                <TextInput source="nice_name"/>
                <TextInput source="description"/>
                <TextInput source="model_type"/>
                <ReferenceInput source="model_type_id" reference="modeltype" label='Type' perPage={1000}>
                    <SelectInput optionText="name" optionValue="id"/>
                </ReferenceInput>
                <SelectInput source="target" choices={choices}/>
                <AddDevicesFromOrg/>
                <AddOrRemoveOne/>
                <Card variant="outlined" fullWidth>
                    <Button color="primary" onClick={ () => {show ? setShow(false) : setShow(true)}} >
                    Show list of devices
                </Button>
                {show ? (
                <ArrayInput source="devices" >
                    <SimpleFormIterator disableReordering>
                    <FormDataConsumer>
                        {({getSource, scopedFormData}) => {
                            if (scopedFormData && (scopedFormData.serial !== undefined)) {
                                return (
                                    <TextField
                                        source={"serial"}
                                        record={scopedFormData}
                                    />
                                );
                            } else {
                                return (
                                    <ReferenceInput record={scopedFormData} label="Device" source={getSource("id")} reference="devices" perPage={5000}>
                                        <AutocompleteInput optionText={DeviceIdentifier}/>
                                    </ReferenceInput>
                                )
                            }
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
                </ArrayInput> ) : "" }
                    </Card>
                <DateTimeInput source="start_time"/>
                <DateTimeInput source="end_time"/>
                <BooleanInput source="enabled"/>
                <JsonInput source="training_parameters" reactJsonOptions={{name: null}}/>
                <JsonInput source="meta" reactJsonOptions={{name: null}}/>
            </SimpleForm>
        </Edit>
    )
}
