import React from 'react';

import Link from '@material-ui/core/Link'

import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    AutocompleteInput,
    FunctionField,
    DateField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    UrlField,
    Labeled,
} from 'react-admin';

import {JsonField, JsonInput} from "react-admin-json-view";

import {
    Edit,
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    SearchInput,
    NumberInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    DateTimeInput,
    SimpleFormIterator,
    ArrayInput,
} from 'react-admin';

import {
    StandardPagination,
    DeviceIdentifier,
} from '../common.js'

import {useRecordContext} from 'react-admin';


/* Button to click for */
export const DashboardLinkField = (props) => {
    const record = useRecordContext(props);

    if (!record) {
        return null;
    }

    console.log('make-schedule-url', record);

    const deviceModelConfig = record.id;
    const grafanaUrl = 'https://dash.soundsensing.no';
    const dashboard = 'v-XNoR8Vk/schedule-detection-visualize';

    const url = `${grafanaUrl}/d/${dashboard}?orgId=1&from=now-30d&to=now&var-device_model_config=${deviceModelConfig}`;

    return <Link href={url}>Open dashboard</Link>;
}
DashboardLinkField.defaultProps = {label: 'Dashboard'};


const listFilters = [
    <ReferenceArrayInput source="model_type_id" reference="modeltype" label="ModelType" perPage={100} alwaysOn>
        <AutocompleteArrayInput optionText="name"/>
    </ReferenceArrayInput>,

    /* FIXME: works only against entries with a single device */
    <ReferenceArrayInput source="device_ids" reference="devices" label="Devices" perPage={10000} alwaysOn>
        <AutocompleteArrayInput optionText="serial"/>
    </ReferenceArrayInput>,

    <SearchInput source="q" alwaysOn/>,
]

export const DeviceModelConfigCreate = props => {

    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput label="Description" source="description"/>
                <DateTimeInput source="start_time"/>
                <DateTimeInput source="end_time"/>
                <ReferenceInput label="model template" source="model_type_id" reference="modeltype" perPage={100}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <JsonInput source="training_parameters" reactJsonOptions={{name: null}}/>
                <JsonInput source="meta" reactJsonOptions={{name: null}}/>
                <ArrayInput source="device_ids">
                    <SimpleFormIterator>
                        <ReferenceInput label="Device" reference="devices" perPage={10000}>
                            <AutocompleteInput optionText={DeviceIdentifier}/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    )
};

export const DeviceModelConfigList = props => (
    <List pagination={<StandardPagination/>} filters={listFilters} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <ReferenceField label="model template" source="model_type_id" reference="modeltype" perPage={100}>
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField label="Creator" source="creator_id" reference="users" perPage={1000}>
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="created_at" showTime/>
            <DateField source="start_time" showTime/>
            <DateField source="end_time" showTime/>
            <ReferenceArrayField label="Devices" reference="devices" source="device_ids" perPage={10000}>
                <SingleFieldList>
                    <ChipField source="serial"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="description"/>
            <JsonField source="training_parameters" reactJsonOptions={{name: null, collapsed: true}}/>
            <JsonField source="meta" reactJsonOptions={{name: null, collapsed: true}}/>
        </Datagrid>
    </List>
);


export const DeviceModelConfigEdit = props => {
    const transform = data => {
        delete data.devices
        delete data.creator_id
        delete data.created_at
        delete data.id

        return data
    }

    return (
        <Edit {...props} transform={transform}>
            <SimpleForm>
                <ReferenceField label="Creator" source="creator_id" reference="users"><TextField
                    source="name"/></ReferenceField>
                <Labeled label="Visualize schedule">
                    <DashboardLinkField/>
                </Labeled>

                <DateField source="created_at" showTime/>
                <ReferenceInput label="Model type" source="model_type_id" reference="modeltype" perPage={100}>
                    <AutocompleteInput optionText="name"/>
                </ReferenceInput>
                <TextInput label="Description" source="description"/>
                <DateTimeInput source="start_time"/>
                <DateTimeInput source="end_time"/>
                <JsonInput source="training_parameters" reactJsonOptions={{name: null}}/>
                <JsonInput source="meta" reactJsonOptions={{name: null}}/>
                <ArrayInput source="device_ids">
                    <SimpleFormIterator>
                        <ReferenceInput label="Device" reference="devices" perPage={10000}>
                            <AutocompleteInput optionText={DeviceIdentifier}/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>


            </SimpleForm>
        </Edit>
    )
};
