
import { apiUrl, httpClient } from './dataProvider';

const localApiTokenKey = 'soundsensing-api-token';

async function getAccount({ username, password }) {
    const url = `${apiUrl}/v1/account`;

    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + btoa(username + ":" + password));
    const response = await fetch(url, { headers: headers });
    if (response.status !== 200) {
        throw new Error(`HTTP error ${response.status}`);
    }
    return await response.json();
}

export default {
    login: ({ username, password }) => {

        return getAccount({ username, password }).then((account) => {
            const token = account.data.api_key;
            localStorage.setItem(localApiTokenKey, token);
        });

    },
   logout: () => {
        localStorage.removeItem(localApiTokenKey);
        return Promise.resolve();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            // No need to log out just cause one resource had permission error
            return Promise.resolve();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        const token = localStorage.getItem(localApiTokenKey); 
        if (!token) {
            return Promise.reject();
        }

        const url = `${apiUrl}/v1/account`;
        return httpClient(url, { token: token });
    },
    getPermissions: () => {
        const token = localStorage.getItem(localApiTokenKey); 
        if (!token) {
            return Promise.reject();
        }
        const url = `${apiUrl}/v1/account`;
        return httpClient(url, { token: token }).then((response) => {
            const account = response.json.data;
            const permissions = account.admin ? ['admin'] : [];
            console.log('authProvider.permissions', permissions);
            return new Set(permissions);
        });
    }
};
